<template>
  <div class="row mx-0 justify-content-center">
    <PaymentGateway :sadaqahSavedReturn="sadaqahSavedReturn" @saveSadaqah="saveSadaqah" @sadaqahComplete="sadaqahComplete" v-model:isLoading="isLoadingValue" />
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'
export default {
  name: 'Wizard 3',
  props: ['sadaqahSavedReturn', 'isLoading'],
  emits: ['saveSadaqah', 'sadaqahComplete', 'update:isLoading'],
  components: {
    PaymentGateway: defineAsyncComponent(() => import('./views/paymentgateway/PaymentGateway.vue'))
  },
  computed: {
    isLoadingValue: {
      get () {
        return this.isLoading
      },
      set (val) {
        this.$emit('update:isLoading', val)
      }
    }
  },
  methods: {
    saveSadaqah () {
      this.$emit('saveSadaqah')
    },
    sadaqahComplete (val) {
      this.$emit('sadaqahComplete', val)
    }
  }
}
</script>
